
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import PropertiesCreating from '@/views/manage/auctions/properties/PropertiesCreating.vue';
  import PropertiesListing from '@/views/manage/auctions/properties/PropertiesListing.vue';
  import { Auction } from '@/store/modules/Auctions/AuctionsModule';
  import Lightgallery from 'lightgallery/vue';
  import lgZoom from 'lightgallery/plugins/zoom';
  import lgVideo from 'lightgallery/plugins/video';

  let lightGallery;

  export default defineComponent({
    name: 'auctions-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      PropertiesCreating,
      PropertiesListing,
      Lightgallery,
    },
    data: () => ({
      plugins: [lgZoom, lgVideo],
    }),
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitVideoButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const createdAuction = ref<Auction | null>(null);
      const selectedOrganizer = ref<number>(0);
      const selectedLocation = ref<number>(0);
      const videoInput = ref('');
      const guideVideo = ref('');
      const certifiedCheckRequest = ref(false);
      const loanRequest = ref(false);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const auctionsSchema = Yup.object().shape({
        organiserId: Yup.number()
          .notOneOf([0], () => translate('ORGANIZER_IS_REQUIRED_FIELD'))
          .required(() => translate('ORGANIZER_IS_REQUIRED_FIELD'))
          .label('Organizer'),
        organisersLocationsId: Yup.number()
          .notOneOf([0], () =>
            translate('ORGANIZER_LOCATION__IS_REQUIRED_FIELD')
          )
          .required(() => translate('ORGANIZER_LOCATION__IS_REQUIRED_FIELD'))
          .label('Organizer Location'),
        eventDatetime: Yup.date()
          .required(() => translate('EVENT_DATE_TIME_IS_REQUIRED_FIELD'))
          .label('Loan Request'),
        loanRequest: Yup.boolean().label('Loan Request'),
        certifiedCheckRequest: Yup.boolean().label('Certified Check Request'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.auctionPropertys = [];
        const result = await store.dispatch(Actions.CREATE_AUCTION, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdAuction.value = result;
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_AUCTION'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };
      const onInit = (detail) => {
        lightGallery = detail.instance;
      };
      const updateSlides = () => {
        lightGallery.refresh();
      };
      const previewVideo = (e) => {
        videoInput.value = e.target.files[0];
        const file = e.target.files[0];
        if (file) {
          guideVideo.value = URL.createObjectURL(file);
          updateSlides();
        }
      };

      const onSubmitVideo = async () => {
        if (submitVideoButton.value) {
          // eslint-disable-next-line
          submitVideoButton.value!.disabled = true;
          submitVideoButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (!videoInput.value) {
          return;
        }

        const videoFormData = new FormData();
        // eslint-disable-next-line
        videoFormData.append('video', videoInput.value);

        await store.dispatch(Actions.UPLOAD_AUCTION_VIDEO, {
          data: videoFormData,
          auctionId: createdAuction.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitVideoButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitVideoButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPLOADED_AUCTION_VIDEO'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitVideoButton.value?.removeAttribute('data-kt-indicator');
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('auctionsCreating'), [
          translate('auctions'),
        ]);
        reinitializeComponents();
      });

      watch(
        () => selectedOrganizer.value,
        async () => {
          if (selectedOrganizer.value) {
            selectedLocation.value = 0;
            await store.dispatch(
              Actions.GET_ORGANIZER_LOCATIONS,
              selectedOrganizer.value
            );
          }
        }
      );
      watch(videoInput, (newVideoInput, oldVideoInput) => {
        if (!newVideoInput) {
          videoInput.value = oldVideoInput;
        }
      });

      await store.dispatch(Actions.GET_ORGANIZERS, { limit: 50 });
      await store.dispatch(Actions.RESET_AUCTIONS_PROPERTYS);
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        submitVideoButton,
        onSubmitVideo,
        onSubmitCreate,
        submitButton,
        translate,
        auctionsSchema,
        goBack,
        can,
        loading,
        createdAuction,
        organizers: computed(() => store.getters.organizersList),
        locations: computed(() => store.getters.organizerLocationsList),
        selectedOrganizer,
        selectedLocation,
        videoInput,
        guideVideo,
        previewVideo,
        onInit,
        updateSlides,
        certifiedCheckRequest,
        loanRequest,
      };
    },
  });
